import { reduxForm, Field, Form } from "redux-form";
import { useEffect, useState } from "react";
import RenderField from "../form-components/RenderField";
import axios from "axios";
import requests from "../utils/Requests";
import { Alert } from "reactstrap";
import { useHistory } from "react-router";
const validate = (values) => {
  const errors = {};
  if (!values.userName) {
    errors.userName = "Enter Email ID";
  }
  if (!values.eMailId) {
    errors.eMailId = "Enter Email Id";
  }
  if (!values.mobileNumber) {
    errors.mobileNumber = "Enter Your Mobile Number";
  }

  if (!values.password) {
    errors.password = "Required";
  } else if (values.password.length < 8) {
    errors.password = "Minimum be 8 characters or more";
  } else if (!/[A-Z]/.test(values.password)) {
    errors.password = "Minimum one upper Case";
  } else if (!/[\d`~!@#$%\^&*()+=|;:'",.<>\/?\\\-]/.test(values.password)) {
    errors.password = "Minimum one special character";
  }

  if (!values.pass) {
    errors.pass = "Required";
  } else if (values.password != values.pass) {
    errors.pass = "Password Miss Match";
  }

  return errors;
};
const RegisterForm = (props) => {
  useEffect(() => {
    props.initialize({ b2c: "true" });
  }, []);
  let history = useHistory();
  const [alertTime, setalertTime] = useState(0);
  const [servserRes, setservserRes] = useState();
  const { handleSubmit, pristine, reset, submitting } = props;
  const handleSubmitReg = (values, dispatch) => {
    const userData = {
      userName: values.eMailId,
      b2c: true,
      b2b: false,
      b2bUser: false,
      customer: {
        customerName: values.userName,
        mobileNumber: values.mobileNumber,
        eMailId: values.eMailId,
        password: values.password,
      },
    };

    axios
      .post(requests.setloginuserdetails, userData)
      .then((res) => {
        // console.log(res.data);
        if (res.data.errorCode == 208) {
          setservserRes(res.data.errorMessage);
          setalertTime(1);
          setTimeout(function () {
            //Put All Your Code Here, Which You Want To Execute After Some Delay Time.
            // Alert.alert("Alert Shows After 5 Seconds of Delay.")

            setalertTime(0);
            props.otpVerify(1);
          }, 5000);
        } else if (res.data.errorCode == 101) {
          //neet to veryfy otp)
        } else {
          let user = res.data.customer.eMailId;
          sessionStorage.setItem("eMail", user);
          props.otpVerify(110);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  return (
    <>
      {alertTime == 1 ? (
        <>
          <Alert color="danger">{servserRes}</Alert>
        </>
      ) : null}
      <Form onSubmit={handleSubmit(handleSubmitReg.bind(this))}>
        {/* <Field
                  name="b2c"
                  type="hidden"
                  component={RenderField.RenderTextField}
                /> */}
        <Field
          name="userName"
          type="text"
          icon=""
          label="Name "
          component={RenderField.RenderTextField}
        />
        <Field
          name="eMailId"
          type="email"
          label="Email"
          component={RenderField.RenderTextField}
        />
        <Field
          name="mobileNumber"
          type="text"
          label="Mobile"
          component={RenderField.RenderTextField}
        />
        <Field
          name="password"
          type="password"
          label="Password"
          component={RenderField.RenderTextField}
        />
        <Field
          name="pass"
          type="password"
          label="Confirm Password"
          component={RenderField.RenderTextField}
        />
        <br />
        <div class="text-lg-center text-left">
          <button type="submit" class="btn btn-yellow" disabled={submitting}>
            Register{" "}
          </button>
        </div>
      </Form>
    </>
  );
};

export default reduxForm({
  form: "RegisterForm",
  validate,
})(RegisterForm);
