/** @format */

import { reduxForm, Form, Field, reset } from "redux-form";
import RenderField from "../form-components/RenderField";
import {
  Row,
  Col,
  Card,
  Button,
  CardHeader,
  CardBody,
  Label,
  Input,
  Alert,
  Tooltip,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap";

import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import { useParams } from "react-router";
import axios from "axios";
import requests from "../utils/Requests";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import TopNav from "../pages/global-components/top_nav";
import Subscribe from "../pages/global-components/subscribe";
import Footer from "../pages/global-components/footer";
import DynamicBanner from "../pages/global-components/dynamic_banner";
import Attractionbygroup from "./attractionByGroup";
import Swal from "sweetalert2";
import { useHistory } from "react-router";
import encryptStorage from "../utils/Encryptstorage";
import { DateUtils } from "@aws-amplify/core";
import { usData } from "../utils/RdData";
const AfterSubmit = (result, dispatch) => {
  dispatch(reset("TicketBookingForm"));
  //window.location.href = "/success";
};
const validate = (values) => {
  const errors = {};

  if (values.attractionsId == 147) {
    if (!values.firstName) {
      errors.firstName = "Required";
    }
    if (!values.lastName) {
      errors.lastName = "Required";
    }
  }
  if (values.BookForAgent) {
    if (!values.agentIdForBookfromSuperAdmin) {
      errors.agentIdForBookfromSuperAdmin = "Required";
    }
  }

  if (values.bookCustomerEmail) {
    if (
      !/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(
        values.bookCustomerEmail
      )
    ) {
      errors.bookCustomerEmail = "Invalid Email ";
    }
  }
  if (!values.bookMobileNumber) {
    errors.bookMobileNumber = "Required";
  } else if (!/^[0-9\b]+$/.test(values.bookMobileNumber)) {
    errors.bookMobileNumber = "Only Numbers Allowed";
  }

  if (!values.bookNofAdult && !values.bookNofChild) {
    errors.bookNofAdult = "Required";
    errors.bookNofChild = "Required";
  }
  if (!values.passengertktType) {
    errors.passengertktType = "Required";
  }
  if (!values.agentRefNumber) {
    errors.agentRefNumber = "Required ";
  }
  if (!values.bookPaymentMode) {
    errors.bookPaymentMode = "Required";
  }
  if (!values.ticketTypeId) {
    errors.ticketTypeId = "Required";
  }
  if (!values.bookTravellDate) {
    errors.bookTravellDate = "Required ";
  }
  return errors;
};

const Packdetail = (props) => {
  const { handleSubmit, pristine, reset, submitting } = props;
  const xmlResponce = usData();

  const hConfig = {
    headers: {
      "access-Key": xmlResponce,
    },
  };
  let history = useHistory();
  const { id } = useParams();
  let publicUrl = process.env.PUBLIC_URL + "/";
  const [agency, setagency] = useState(0);
  const [agencyuser, setagencyuser] = useState(0);
  const [userId, setuserId] = useState(0);
  let addonget = { attractionId: id };
  const [LoggedIn, setLoggedIn] = useState();
  const [bookingAddon, setbookingAddon] = useState([]);
  const [tourpack, setTourpack] = useState([]);
  const [isLoading, setisLoading] = useState(true);
  const [adultfare, setadultfare] = useState(0);
  const [childfare, setchildfare] = useState(0);
  const [numOfAdult, setnumOfAdult] = useState(0);
  const [numOfChild, setnumOfChild] = useState(0);
  const [addonPrice, setaddonPrice] = useState(0);
  const [b2cUser, setb2cUser] = useState(0);
  const [OpenTicket, setOpenTicket] = useState("");
  const [UserCredit, setUserCredit] = useState(1);

  //for Form Submit

  const [insuffBal, setinsuffBal] = useState(0);
  const [paymentSelection, setpaymentSelection] = useState(0);
  const [showAlert, setshowAlert] = useState(0);
  const [userdata, setuserdata] = useState([]);
  const [adultFieldDisable, setadultFieldDisable] = useState();
  const [childFieldDisable, setchildFieldDisable] = useState();
  const [seletedTicketId, setseletedTicketId] = useState();

  const [showOption, setShowOption] = useState(false);
  const [splError, setsplError] = useState();
  let login = sessionStorage.getItem("isLoggedIn");
  useEffect(() => {
    console.log("thiravugol", xmlResponce);
    encryptStorage.removeItem("DFuck");
    encryptStorage.removeItem("url");
    encryptStorage.removeItem("suckapi");
    encryptStorage.removeItem("firstSubmitForBooking");
    encryptStorage.removeItem("mofForBooking");
    window.scrollTo(0, 0);

    if (login == "yes") {
      const enl = encryptStorage.getItem("enl"); //obj //agency.agencyId
      let urlObject = JSON.parse(enl);
      console.log("urlObject", urlObject);
      setuserdata(urlObject);
      //b2b
      if (urlObject.b2b == true) {
        if (urlObject.agency.superAdmin === true) {
          getAllAgentList();
        }
        props.initialize({
          attractionsId: id,
          bookPaymentMode: 1,
          bookMobileNumber: urlObject.agency.agencyPhoneNumber,
          bookCustomerEmail: urlObject.agency.agencyEmail,
          //agencyEmail
        });
        setuserEmail(urlObject.agency.agencyEmail);
        let agency = urlObject.agency.agencyId;

        setsplError(urlObject.agency.agencyPassword);
        setagency(agency);
        getTourDeail(agency, 0);
        setLoggedIn(1);
      } else if (urlObject.b2bUser == true) {
        props.initialize({
          attractionsId: id,
          bookPaymentMode: 1,
          //  bookMobileNumber: urlObject.agency.agencyPhoneNumber,
        });
        let agencyuser = urlObject.agencyUser.agencyUserId;
        setsplError(urlObject.agencyUser.userPassword);
        setagencyuser(agencyuser);
        getTourDeail(0, agencyuser);
        setLoggedIn(1);
      } else {
        props.initialize({
          attractionsId: id,
          bookPaymentMode: 1,
          //  bookMobileNumber: urlObject.agency.agencyPhoneNumber,
        });
        setuserId(urlObject.customer.customerId);
        setb2cUser(1);
        getTourDeail(0, 0);
        setLoggedIn(1);
        setOpenTicket(2);
        //  props.initialize({});
      }
    } else {
      setOpenTicket(2);
      getTourDeail(0, 0);
      setLoggedIn(0);
      props.initialize({
        attractionsId: id,
        bookPaymentMode: 1,
        // bookMobileNumber: "lahdiuad",
      });
    }

    getFormAddons();
    getBannerAddons();
    apicalltkt(id);
    getAllHolydays(id);

    // checkKey();
  }, []);

  const logout = (e) => {
    sessionStorage.clear();
    window.location.reload();
    // console.log("clear");
    window.location.href = "/";
  };

  //   console.log("check api");
  //   axios
  //     .post(
  //       "https://parmartours.com:8443/parmartour-test/v1.0//setBookingIp",
  //       { userId: 1 },
  //       hConfig
  //     )
  //     .then((res) => {
  //       console.log("res");
  //       console.log(res.data);
  //     })
  //     .catch((error) => {
  //       console.log(error);
  //     });
  // };
  // let attraction = { attractionsId: id,  agencyId: agency , agencyUserId: agencyuser};
  let atID;
  const getTourDeail = async (b2bid, b2buserid) => {
    await axios
      .post(requests.getAttractionDetails, {
        attractionsId: id,
        agencyId: b2bid,
        agencyUserId: b2buserid,
        currencyCode: "",
      })
      .then((res) => {
        setTourpack(res.data);
        console.log(res.data);
        //attConnectWithApi
        // const [OpenTicket, setOpenTicket] = useState(1);

        if (res.data.alertUser === true) {
          Swal.fire("Information!", `${res.data.alertMessage}`, "info");
        }
        if (res.data.attConnectWithApi == true) {
          if (res.data.openTicketOption == true) {
            if (b2bid !== 0 || b2buserid !== 0) {
              setOpenTicket("");
            } else {
              setOpenTicket(2);
            }
          } else {
            setOpenTicket(2);
          }
          //
        }

        setisLoading(false);
        // settktfare(tourpack.price)
        // console.log(res.data);
        if (LoggedIn == 1) {
          if (userdata.b2b == true || userdata.b2bUser == true) {
            setadultfare(res.data.b2bAdultPrice);
            setchildfare(res.data.b2bChildPrice);
          } else {
            setadultfare(res.data.b2cAdultPrice);
            setchildfare(res.data.b2cChildPrice);
          }
        } else {
          setadultfare(res.data.b2cAdultPrice);
          setchildfare(res.data.b2cChildPrice);
        }
        atID = res.data.attGroup;
        getAttAllByGroup(atID);
        sessionStorage.setItem("attname", res.data.attName);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const [attractionList, setattractionList] = useState([]);
  // let GroupId = { attGroup: tourpack.attGroup };
  const [AttGrouFilter, setAttGrouFilter] = useState([]);
  const getAttAllByGroup = async (gId) => {
    await axios
      .post(requests.getattractionallbygroup, { attGroup: gId })
      .then((res) => {
        setattractionList(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const [formAddOn, setformAddOn] = useState();
  const [isFormLoading, setisFormLoading] = useState(true);
  const getFormAddons = async () => {
    await axios
      .post(requests.setaddonformlist, addonget)
      .then((res) => {
        setformAddOn(res.data);
        setisFormLoading(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const [bannerAddOn, setbannerAddOn] = useState();
  const [isBannerLoading, setisBannerLoading] = useState(true);
  const getBannerAddons = async () => {
    await axios
      .post(requests.getaddonbannerlist, addonget)
      .then((res) => {
        setbannerAddOn(res.data);
        setisBannerLoading(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const [tkttypeTemp, settkttypeTemp] = useState([]);
  const [apiTicketType, setApiTicketType] = useState([]);

  const [holydays, setHolydays] = useState([]);
  const [showHolydays, setshowHolydays] = useState([""]);

  //get Holydays
  const getAllHolydays = (ticketTyptId) => {
    setHolydays("");
    axios
      .post(requests.getAttractionHolidayListDto, { attractionId: id })
      .then((res) => {
        // let allHolydays = getDatesInRange(res.data);

        console.log("all leave days", res.data);

        setshowHolydays(res.data);

        getDatesInRangeholydays(res.data);

        for (let i = 0; i < res.data.length; i++) {
          if (res.data[i].ticketTypeId == 0) {
            let templist = getDatesInRangeholydays(
              res.data[i].startDate,
              res.data[i].endDate
            );
            setHolydays(templist);
          } else if (res.data[i].ticketTypeId == ticketTyptId) {
            console.log("else if");
            let templist = getDatesInRangeholydays(
              res.data[i].startDate,
              res.data[i].endDate
            );

            setHolydays(templist);
          }
        }

        // tempAllHolydays.push(tempTktHolyday);

        let allHolydays = getDatesInRange(res.data);

        // setHolydays(allHolydays);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getDatesInRange = (getArrayList) => {
    const dates = [];

    let tempArrDateList = getArrayList;

    const date = new Date(tempArrDateList.startDate);
    const enddate = new Date(tempArrDateList.endDate);
    // ✅ Exclude start date
    // date.setDate(date.getDate() + 1);
    // ✅ Exclude end date
    while (date <= enddate) {
      dates.push(new Date(date));

      date.setDate(date.getDate() + 1);
    }

    console.log("holyay =======================>s", dates);

    return dates;
  };

  const getDatesInRangeholydays = (startDate, endDate) => {
    const date = new Date(startDate);
    const enddate = new Date(endDate);
    // ✅ Exclude start date

    const dates = [];

    // ✅ Exclude end date
    while (date <= enddate) {
      dates.push(new Date(date));

      date.setDate(date.getDate() + 1);
    }

    return dates;
  };
  // get all agency user to list out for booking super admin

  const [agentList, setagentList] = useState([]);
  const [agencyDetails, setagencyDetails] = useState([]);

  const getAllAgentList = () => {
    axios
      .post(requests.getagencylist, {
        attractionsId: 1,
        secretKey: requests.apiKey,
      })
      .then((response) => {
        console.log("Agency List", response.data);
        let agent = [...agentList];
        setagencyDetails(response.data);
        agent.length = 0;
        for (let i = 0; i < response.data.length; i++) {
          //  console.log(response.data[i].agencyName);  //agencyId
          agent.push({
            label: response.data[i].agencyName,
            value: response.data[i].agencyId,
          });
        }
        setagentList(agent);
        // console.log(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const [agentIdForBook, setagentIdForBook] = useState(0);

  const setAgentIdAndResetTkt = (e) => {
    setagentIdForBook(e);

    console.log(e);
    getagentBalance(e);
  };
  const getagentBalance = (id) => {
    console.log(id);
    let AvailBal = 0;

    const user = agencyDetails.find((item) => item.agencyId === id);
    AvailBal = user?.currentBalance;

    return AvailBal;
  };

  const [ticketFullData, setticketFullData] = useState([]);

  const apicalltkt = (attId) => {
    axios
      .post(requests.getTicketTypeListByAttraction, { ttAttractionId: attId })
      .then((res) => {
        const filterticket = res.data
          .filter((item) => item.showInParmarTours === true)
          .map((item) => ({
            ...item,
          }));

        console.log(
          "res.dat ================================================================================a",
          filterticket
        );
        setticketFullData(res.data);
        const values = [...tkttypeTemp];
        values.length = 0;
        values.push({
          name: "Select Ticket Type",
          value: "",
          // eventtypeId: "",
          // resourceID: "",
        });

        const apiTicket = [...apiTicketType];
        apiTicket.length = 0;
        apiTicket.push({
          name: "Select Ticket Type",
          value: "",
          eventtypeId: "",
          resourceID: "",
        });

        for (let i = 0; i < filterticket.length; i++) {
          if (
            filterticket[i].resourceID == null ||
            filterticket[i].resourceID == 0
          ) {
            values.push({
              name: filterticket[i].ttTicketType,
              value: filterticket[i].ticketTypeId,
              // eventtypeId: res.data[i].eventtypeId,
              // resourceID: res.data[i].resourceID,
            });
          } else {
            apiTicket.push({
              name: filterticket[i].ttTicketType,
              value: filterticket[i].ticketTypeId,
              eventtypeId: filterticket[i].eventtypeId,
              resourceID: filterticket[i].resourceID,
            });
          }
        }
        setApiTicketType(apiTicket);
        // console.log("value", values);
        settkttypeTemp(values);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const [tempErr, settempErr] = useState(false);

  const checkboxvalues = (e) => {
    //checkAddonBarcodeAvailability

    settempErr(false);
    //console.log("e", e.target.value);

    let val = e.target.id;
    let newprice = e.target.value;

    let tempAddon = [...bookingAddon];

    if (e.target.checked == true) {
      let finalprice = Number(addonPrice) + Number(newprice);
      setaddonPrice(finalprice);
      let tempEle = { addonId: val };
      tempAddon.push(tempEle);
      setbookingAddon(tempAddon);

      // console.log("tempAddon", tempAddon);
      const tempData = {
        bookingAddon: tempAddon,
        secretKey: requests.apiKey,
      };
      // console.log(`${JSON.stringify(tempData, null, 2)}`);
      axios
        .post(requests.checkAddonBarcodeAvailability, tempData)
        .then((response) => {
          //  console.log(`${JSON.stringify(response.data, null, 2)}`);

          //  console.log("response.data", response.data);
          if (response.data.errorCode == 600) {
            settempErr(true);
          } else if (response.data.errorCode == 601) {
            settempErr(true);
          } else {
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
      let finalprice = Number(addonPrice) - Number(newprice);
      setaddonPrice(finalprice);
      let temp = bookingAddon.filter((item) => item.addonId != val);
      setbookingAddon(temp);
    }
  };

  const [tktAvailability, settktAvailability] = useState(0);
  const [servserRes, setservserRes] = useState();
  const [showTktFare, setshowTktFare] = useState(0);
  const [actAdultfare, setactAdultfare] = useState();
  const [actChildfare, setactChildfare] = useState();
  const [setEventIdErr, setsetEventIdErr] = useState(false);
  const [eveId, seteveId] = useState("");
  const [resId, setresId] = useState("");
  const [ticketName, setticketName] = useState();

  // const tooltip = (
  //   <Tooltip id="tooltip">
  //     <strong>Holy guacamole!</strong> Check this info.
  //   </Tooltip>
  // );
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const [childTooltipOpen, setchildTooltipOpen] = useState(false);
  const [adultTooltipOpen, setadultTooltipOpen] = useState(false);
  const [adultExpiry, setadultExpiry] = useState("");
  const [childExpiry, setchildExpiry] = useState("");

  const childtoggle = () => {
    setchildTooltipOpen(!childTooltipOpen);
  };
  const adulttoggle = () => {
    setadultTooltipOpen(!adultTooltipOpen);
  };

  const [fullTicketData, setfullTicketData] = useState();
  const [adultClicked, setadultClicked] = useState(false);
  const [childClicked, setchildClicked] = useState(false);

  const handleFieldClickChild = () => {
    setchildClicked(true);
  };
  const handleFieldClickAdult = () => {
    setadultClicked(true);
  };

  const gettktDisPrice = (val) => {
    console.log("kajsbcxisbcisbcs", val);
    setseletedTicketId(val);
    props.change("bookTravellDate", null);
    settimeSlot("");
    setselectTimeSlotRes("");
    setadultClicked(false);
    setchildClicked(false);
    props.change("bookNofChild", null);
    props.change("bookNofAdult", null);
    setfullTicketData("");
    getAllHolydays(val);
    setTooltipOpen(false);
    //ticketFullData
    setadultExpiry("");
    setchildExpiry("");
    const findData = ticketFullData.find((item) => item.ticketTypeId == val);

    let agencyId = agency;
    if (agentIdForBook !== 0) {
      agencyId = agentIdForBook;
    }
    //agentIdForBook

    if (findData) {
      if (findData?.altUser === true) {
        Swal.fire("Information!", `${findData?.altMessage}`, "info");
      }
    }
    let tempagentId;
    settktAvailability(0);
    setchildfare();
    setadultfare();
    setadultFieldDisable(false);
    setchildFieldDisable(false);

    if (tourpack.attConnectWithApi == true) {
      if (OpenTicket == 1 || OpenTicket == "") {
        let temp = tkttypeTemp.filter((item) => item.value == val);
        // console.log("temp  if ", temp)
        setticketName(temp[0].name);
      } else if (OpenTicket == 2) {
        let temp = apiTicketType.filter((item) => item.value == val);

        // console.log("temp else", temp)
        setticketName(temp[0].name);
      }
    } else {
      let temp = tkttypeTemp.filter((item) => item.value == val);

      // console.log("temp else", temp)
      setticketName(temp[0].name);
    }
    if (val) {
      if (id == 147) {
        //baseURLMof  MOF
      } else {
        if (tourpack.attConnectWithApi == true) {
          if (OpenTicket == 2) {
            let temp = apiTicketType.filter((item) => item.value == val);
            // console.log("sdsdsdsdsdsdsdsd", temp); //eventtypeId, resourceID

            seteveId(temp[0].eventtypeId);
            setresId(temp[0].resourceID);
            setsetEventIdErr(false);
          } else {
            console.log(
              "Submit Data",
              `${JSON.stringify(
                {
                  ticketTypeId: val,
                  agencyId: agencyId,
                  agencyUserId: agencyuser,
                  attractionsId: id,
                },
                null,
                2
              )}`
            );
            axios
              .post(requests.getAttractionTicketTypePrice, {
                ticketTypeId: val,
                agencyId: agencyId,
                agencyUserId: agencyuser,
                attractionsId: id,
              })
              .then((res) => {
                console.log("ticket Alert", res.data);
                setfullTicketData(res.data);
                //adultTktExpiry
                if (res.data.adultTktExpiry) {
                  setadultExpiry(`Expiry Date : ${new Date(
                    res.data.adultTktExpiry
                  ).toLocaleDateString()}
                  `);
                }
                if (res.data.childTktExpiry) {
                  setchildExpiry(
                    `Expirt Date : ${new Date(
                      res.data.childTktExpiry
                    ).toLocaleDateString()}`
                  );
                }
                // setTooltipOpen(true);
                // errorCode 505 No Adult Ticket
                // errorCode 504 No Child Ticket
                // console.log("Ticket Price", res.data);

                if (LoggedIn == 1 && b2cUser == 0) {
                  // for b2b

                  // console.log("")
                  if (res.data.errorCode == 505) {
                    setadultfare(0);
                    if (res.data.b2bChildPrice !== 0) {
                      setshowTktFare(1);
                      if (res.data.b2bChildDisPrice == 0) {
                        setchildfare(res.data.b2bChildPrice);
                      } else {
                        setchildfare(res.data.b2bChildDisPrice);
                      }
                    } else {
                      setshowTktFare(1);
                      setservserRes("Adult Ticket Not Available");
                      // settktAvailability(1);
                      setnumOfAdult(0);
                      setadultFieldDisable(true);
                      setnumOfChild(0);
                      setchildFieldDisable(true);
                      settktAvailability(1);
                    }
                    // setadultFieldDisable(true);
                    // setchildFieldDisable(true);
                    settktAvailability(1);
                  } else if (res.data.errorCode == 504) {
                    if (res.data.b2bAdultDisPrice == 0) {
                      setadultfare(res.data.b2bAdultPrice); //b2bChildDisPrice
                      setchildfare(0);
                      setshowTktFare(1);
                      setservserRes("Child Ticket Not Available");
                      setnumOfChild(0);
                      // setchildFieldDisable(true);
                      settktAvailability(1);
                    } else {
                      setadultfare(res.data.b2bAdultDisPrice);
                      setchildfare(0);
                      setshowTktFare(1);
                      setnumOfChild(0);
                      // setchildFieldDisable(true);
                      setservserRes("Child Ticket Not Available");
                      settktAvailability(1);
                    }
                  } else {
                    if (res.data.b2bAdultDisPrice == 0) {
                      setadultfare(res.data.b2bAdultPrice); //b2bChildDisPrice
                      setchildfare(res.data.b2bChildPrice);
                      setshowTktFare(1);
                      settktAvailability(0);
                    } else {
                      setadultfare(res.data.b2bAdultDisPrice); //b2bChildDisPrice
                      setchildfare(res.data.b2bChildDisPrice);
                      setshowTktFare(1);
                      settktAvailability(0);
                    }
                  }
                } else {
                  //for b2c

                  setadultFieldDisable(true);
                  if (res.data.errorCode == 505) {
                    setnumOfAdult(0);
                    setnumOfChild(0);

                    // setadultFieldDisable(true);
                    // setchildFieldDisable(true);
                    setshowTktFare(0);
                    setservserRes("Ticket Not Available");
                    settktAvailability(1);
                  } else if (res.data.errorCode == 504) {
                    setadultfare(res.data.b2cAdultPrice); //b2bChildDisPrice
                    setchildfare(0);
                    setshowTktFare(1);

                    setnumOfChild(0);

                    // setchildFieldDisable(true);
                    setservserRes("Child Ticket Not Available");
                    settktAvailability(1);
                  } else {
                    setadultfare(res.data.b2cAdultPrice);
                    setchildfare(res.data.b2cChildPrice);
                    setshowTktFare(1);
                    settktAvailability(0);
                  }
                }
              })
              .catch((err) => {});
          }
        } else {
          axios
            .post(requests.getAttractionTicketTypePrice, {
              ticketTypeId: val,
              agencyId: agencyId,
              agencyUserId: agencyuser,
              attractionsId: id,
            })
            .then((res) => {
              console.log("ticket price data", res.data);

              setfullTicketData(res.data);
              // errorCode 505 No Adult Ticket
              // errorCode 504 No Child Ticket
              // console.log("Ticket Price", res.data);

              //adultTktExpiry
              if (res.data.adultTktExpiry) {
                setadultExpiry(
                  `Expiry Date : ${new Date(
                    res.data.adultTktExpiry
                  ).toLocaleDateString()}`
                );
              }
              if (res.data.childTktExpiry) {
                setchildExpiry(
                  `Expiry Date : ${new Date(
                    res.data.childTktExpiry
                  ).toLocaleDateString()}`
                );
              }

              // setTooltipOpen(true);

              if (LoggedIn == 1 && b2cUser == 0) {
                // for b2b

                // console.log("")
                if (res.data.errorCode == 505) {
                  setadultfare(0);
                  if (res.data.b2bChildPrice !== 0) {
                    setshowTktFare(1);
                    if (res.data.b2bChildDisPrice == 0) {
                      setchildfare(res.data.b2bChildPrice);
                    } else {
                      setchildfare(res.data.b2bChildDisPrice);
                    }
                  } else {
                    setshowTktFare(1);
                    setservserRes("Adult Ticket Not Available");
                    // settktAvailability(1);
                    setnumOfAdult(0);
                    setadultFieldDisable(true);
                    setnumOfChild(0);
                    setchildFieldDisable(true);
                    settktAvailability(1);
                  }
                } else if (res.data.errorCode == 504) {
                  if (res.data.b2bAdultDisPrice == 0) {
                    setadultfare(res.data.b2bAdultPrice); //b2bChildDisPrice
                    setchildfare(0);
                    setshowTktFare(1);
                    setservserRes("Child Ticket Not Available");
                    settktAvailability(1);
                    setnumOfChild(0);
                    setchildFieldDisable(true);
                  } else {
                    setadultfare(res.data.b2bAdultDisPrice);
                    setchildfare(0);
                    setshowTktFare(1);
                    setservserRes("Child Ticket Not Available");

                    setnumOfChild(0);
                    setchildFieldDisable(true);
                    settktAvailability(1);
                  }
                } else {
                  if (res.data.b2bAdultDisPrice == 0) {
                    setadultfare(res.data.b2bAdultPrice); //b2bChildDisPrice
                    setchildfare(res.data.b2bChildPrice);
                    setshowTktFare(1);
                    settktAvailability(0);
                  } else {
                    setadultfare(res.data.b2bAdultDisPrice); //b2bChildDisPrice
                    setchildfare(res.data.b2bChildDisPrice);
                    setshowTktFare(1);
                    settktAvailability(0);
                  }
                }
              } else {
                //for b2c

                // 505 adult not available
                // child not available --- 504

                if (res.data.errorCode == 505) {
                  setnumOfAdult(0);
                  setadultFieldDisable(true);
                  setnumOfChild(0);
                  setchildFieldDisable(true);

                  setshowTktFare(0);
                  setservserRes("Ticket Not Available");
                  settktAvailability(1);
                } else if (res.data.errorCode == 504) {
                  setadultfare(res.data.b2cAdultPrice); //b2bChildDisPrice
                  setchildfare(0);
                  setshowTktFare(1);
                  setservserRes("Child Ticket Not Available");
                  setnumOfChild(0);
                  setchildFieldDisable(true);
                  settktAvailability(1);
                } else {
                  setadultfare(res.data.b2cAdultPrice);
                  setchildfare(res.data.b2cChildPrice);
                  setshowTktFare(1);
                  settktAvailability(0);
                }
              }
            })
            .catch((err) => {});
        }
      }
    }
  };
  // Ticket timeslot

  const [timeSlot, settimeSlot] = useState();
  const [slot, setslot] = useState(false);
  const [loaderForSlot, setloaderForSlot] = useState(false);
  const [traveldate, settraveldate] = useState();
  const [mofTimeSlot, setmofTimeSlot] = useState([]);
  const [mofAvailable, setmofAvailable] = useState(null);
  const checkTimeSlot = async (dateVal) => {
    //2023-01-20    2023-1-20
    setmofTimeSlot("");
    let formetDate = `${dateVal.getFullYear()}-${(
      "0" +
      (dateVal.getMonth() + 1)
    ).slice(-2)}-${("0" + dateVal.getDate()).slice(-2)}`;

    settraveldate(formetDate);
    if (tourpack.attConnectWithApi == true) {
      if (OpenTicket == 2) {
        if (eveId != "") {
          setloaderForSlot(true);
          setshowTktFare(0);
          setslot(false);
          setsetEventIdErr(false);
          //  console.log(dateVal + eveId + resId);
          // console.log({
          //   bookingDate: formetDate,
          //   eventTypeId: eveId,
          //   resourceId: resId,
          //   secretKey: requests.apiKey,
          // });

          const postData = {
            bookingDate: formetDate,
            eventTypeId: eveId,
            resourceId: resId,
            secretKey: requests.apiKey,
          };
          console.log("Submit Data", `${JSON.stringify(postData, null, 2)}`);
          await axios
            .post(requests.getBurjTimeSlotWithRates, {
              bookingDate: formetDate,
              eventTypeId: eveId,
              resourceId: resId,
              secretKey: requests.apiKey,
            })
            .then((response) => {
              console.log(
                "Submit Data",
                `${JSON.stringify(response.data, null, 2)}`
              );
              // console.log("Time Slot", response.data);
              settimeSlot(response.data.agentServiceEventsPrice);
              setloaderForSlot(false);
              setslot(true);
            })
            .catch(() => {
              setslot(false);
            });
        } else {
          setsetEventIdErr(true);
        }
      }
    } else if (id == 147) {
      const postObject = {
        startDate: formetDate ? formetDate : traveldate,
        endDate: formetDate ? formetDate : traveldate,
        accessAreaCode: seletedTicketId == 436 ? "MOTFLC" : "VISA Pioneer",
      };

      console.log("postObject ", `${JSON.stringify(postObject, null, 2)}`);
      setmofAvailable("Please Wait");

      await axios
        .post(requests.getAvailability, postObject)
        .then((res) => {
          console.log("res Data", `${JSON.stringify(res.data, null, 2)}`);
          if (res.data.errorCode == "200") {
            if (res.data.slotAvailability !== null) {
              //slotAvailability
              if (seletedTicketId == 480) {
                getmofSelected(res.data.slotAvailability[0]);
              }
              setmofTimeSlot(res.data);
              setmofAvailable(null);
            } else {
              setmofAvailable(
                "Time Slot Not Available Please Select Another Date"
              );
            }
          } else {
            setmofAvailable(null);
            Swal.fire({
              icon: "error",
              title: "ERROR",
              text: res.data.errorMessage,
            });
          }

          // if (res.data.slotAvailability !== null) {
          //   setmofTimeSlot(res.data);
          //   setmofAvailable(null);
          // } else {
          //   setmofAvailable(
          //     "Time Slot Not Available Please Select Another Date"
          //   );
          // }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  const [mofSeletedTicket, setmofSeletedTicket] = useState();
  const [mofCartData, setmofCartData] = useState();
  const [fName, setfName] = useState("");
  const [lName, setlName] = useState("");
  const [userEmail, setuserEmail] = useState("");
  const getmofSelected = async (item) => {
    setmofSeletedTicket(item);
    console.log("item", item);
    const timeSlot =
      item.dateTimeFrom.slice(11, -13) + "-" + item.dateTimeTo.slice(11, -13);
    const postObject = {
      tktid: seletedTicketId,
      accessAreaCode: seletedTicketId == 436 ? "MOTFLC" : "VISA Pioneer",
      performanceId: item.performanceId,
      // timeSlot:
      //   item.dateTimeFrom.slice(11, -13) + "-" + item.dateTimeTo.slice(11, -13),
    };
    console.log(`${JSON.stringify(postObject, null, 2)}`);

    axios
      .post(requests.getTicketPrice, postObject)
      .then((res) => {
        props.change("mofperformanceId", item.performanceId);
        props.change("moftimeSlot", timeSlot);
        props.change(
          "accessAreaCode",
          seletedTicketId == 436 ? "MOTFLC" : "VISA Pioneer"
        );

        console.log(res.data);

        setshowTktFare(1);

        if (res.data.adultPrice != "0") {
          setadultfare(res.data.adultPrice);
        }
        if (res.data.childPrice != "0") {
          setchildfare(res.data.childPrice);
        }
      })
      .catch((error) => {
        console.log(error);
      });
    //   await axios
    //     .post(requests.addToCart, postObject)
    //     .then((res) => {
    //       if (res.data.errorCode == "200") {
    //         console.log(res.data);
    //         console.log(`${JSON.stringify(res.data, null, 2)}`);
    //         setmofCartData(res.data);
    //       } else {
    //         Swal.fire({
    //           icon: "error",
    //           title: res.data.errorCode,
    //           text: res.data.errorMessage,
    //         });
    //       }
    //  ;
    //     })
    //     .catch((error) => {
    //       console.log(error);
    //     });
  };

  const [bookTimeSlot, setbookTimeSlot] = useState("");
  const [selectTimeSlotRes, setselectTimeSlotRes] = useState([]);

  const [agentServiceTicketTypes, setagentServiceTicketTypes] = useState([]);
  const selectedTimeSlot = (
    eventID,
    eventName,
    startDateTime,
    endDateTime,
    eventTypeID,
    resourceID,
    available,
    status
  ) => {
    //getBurjTicketTypeWithRate
    setadultfare(""); //b2bChildDisPrice
    setchildfare("");

    const getRate = {
      agencyId: agency,
      agencyUserId: agencyuser,
      bookB2cId: userId,
      available: available,
      endDateTime: endDateTime,
      eventId: eventID,
      eventName: eventName,
      eventTypeId: eventTypeID,
      resourceId: resourceID,
      startDateTime: startDateTime,
      status: status,
      secretKey: requests.apiKey,
    };

    // console.log("sumit Data", getRate);
    console.log(`${JSON.stringify(getRate, null, 2)}`);
    axios
      .post(requests.getBurjTicketTypeWithRates, getRate)
      .then((response) => {
        console.log(`${JSON.stringify(response.data, null, 2)}`);
        setselectTimeSlotRes(response.data);

        const compareDate = new Date("2024-08-31T23:59:59"); // "2024-09-31" is invalid, using last moment of 2024-09-30 instead

        console.log(new Date(startDateTime), compareDate);

        // if (seletedTicketId == 89 && new Date(startDateTime) > compareDate) {
        //   setadultfare(285); //b2bChildDisPrice
        //   setchildfare(255);
        // } else {
        //   setadultfare(Math.round(response.data.adultPrice)); //b2bChildDisPrice
        //   setchildfare(Math.round(response.data.childPrice));
        // }

        setadultfare(Math.round(response.data.adultPrice)); //b2bChildDisPrice
        setchildfare(Math.round(response.data.childPrice));

        if (response.data.childPrice === 0) {
          setnumOfChild(0);
          setchildFieldDisable(true);
        }

        // setnumOfAdult(0);
        // setadultFieldDisable(true);

        //setagentServiceTicketTypes(response.data.agentServiceTicketTypes);
        setshowTktFare(1);
      })
      .catch((error) => {
        console.log(error);
      });

    setbookTimeSlot(startDateTime);
  };

  const [agentSelErr, setagentSelErr] = useState(false);
  const [refresh, doRefresh] = useState(0);
  const [totalerr, settotalerr] = useState(false);
  const [mofNotSelected, setMofNotSelected] = useState();
  const onSubmit = (values) => {
    console.log(`${JSON.stringify(values, null, 2)}`);
    // e.preventdefault()

    let subErr = true;
    let child_count, adult_count;
    if (!values.bookNofChild) {
      child_count = 0;
    } else {
      child_count = values.bookNofChild;
    }

    let total = Number(
      adultfare * numOfAdult +
        childfare * child_count +
        addonPrice * numOfAdult +
        addonPrice * numOfChild
    );

    console.log("iaxbiaxiuauxgagxga total -------->", total);

    if (total == 0 || total == "") {
      subErr = false;
    } else if (values.ticketTypeId == "" || values.ticketTypeId == 0) {
      subErr = false;
    }
    let bookedByBackOffice,
      bookedCreditLimit = UserCredit,
      superAdminId = 0,
      paymentMode = values.bookPaymentMode,
      b2bagentid = agency;

    if (LoggedIn == 1) {
      if (userdata.b2b == true || userdata.b2bUser == true) {
        if (userdata.b2b == true && userdata.agency.superAdmin === true) {
          // console.log("Super Agent True ");
          superAdminId = userdata.agency.agencyId;
          if (showOption === true) {
            bookedByBackOffice = true;
            // bookedCreditLimit = UserCredit;

            b2bagentid = agentIdForBook;
            paymentMode = 2;
          } else {
            bookedByBackOffice = false;
            // bookedCreditLimit = 2;
            // superAdminId = userdata.agency.agencyId;
            b2bagentid = userdata.agency.agencyId;
          }
          // if (LoggedIn == 1) {
          //   if (paymentMode == 1) {
          //     total = total + (2.9 / 100) * total;
          //   }
          // }
        }
      }
    }
    if (paymentMode == 1) {
      total = total + (2.5 / 100) * total;
    }
    // For filter attraction
    const apiticketname = apiTicketType?.find(
      (item) => item.value == values.ticketTypeId
    );

    console.log(
      "apiticketnameapiticketnameapiticketnameapiticketnameapiticketnameapiticketname",
      apiticketname
    );
    const submitdata = {
      bookedByBackOffice: bookedByBackOffice,
      bookedCreditLimit: bookedCreditLimit,
      superAdminId: superAdminId,
      bookB2bId: b2bagentid,
      // bookB2bId: UserCredit == 1 ? agentIdForBook: userdata.agency.agencyId,
      bookB2bUserId: agencyuser,
      bookB2cId: userId,
      attractionsId: values.attractionsId,
      bookCustomerName: values.bookCustomerName,
      bookCustomerEmail: values.bookCustomerEmail,
      bookMobileNumber: values.bookMobileNumber,
      ticketTypeId: values.ticketTypeId,
      bookNofAdult: values.bookNofAdult,
      bookNofChild: child_count,
      bookAdultPrice: adultfare,
      bookChildPrice: childfare,
      bookTravellDate: values.bookTravellDate, //traveldate
      // bookPaymentMode: values.bookPaymentMode,
      bookTravellDate: traveldate,
      bookPaymentMode: paymentMode,
      bookTotal: total,
      bookingAddon: bookingAddon,
      secretKey: requests.apiKey,
      errorMessage: splError,
      agentRefNumber: values.agentRefNumber,
    };

    encryptStorage.setItem("DFuck", submitdata);

    //encryptStorage.setItem("wps", OpenTicket);
    let api_paymenyt;
    if (tourpack.attConnectWithApi == true) {
      if (OpenTicket == 2) {
        api_paymenyt = 2;
      }
    } else {
      api_paymenyt = 1;
    }

    const apiTicketPayment = {
      OpenTickets: api_paymenyt,
    };

    //console.log("Api de  -- 1", apiTicketPayment)
    encryptStorage.setItem("suckapi", apiTicketPayment);

    //Check For Cart Or Book Now
    // console.log("Submit Data", `${JSON.stringify(submitdata, null, 2)}`);
    if (values.pill == 1) {
      // pill = 1 book now

      if (subErr == true) {
        if (LoggedIn == 1) {
          if (userdata.b2b == true || userdata.b2bUser == true) {
            if (OpenTicket == 1 || OpenTicket == "") {
              // console.log("Open Ticket");

              if (id == 147) {
                console.log("Mof Attraction");

                if (!values.moftimeSlot && !values.mofperformanceId) {
                  Swal.fire({
                    icon: "error",
                    title: "Error",
                    text: "Ensure Your Time slot is selected",
                  });
                } else {
                  const postObjectTemp = {
                    // tktid: seletedTicketId,

                    firstName: fName,
                    lastName: lName,
                    nofAdult: values.bookNofAdult,
                    nofChild: child_count,
                    timeSlot: values.moftimeSlot,
                    performanceId: values.mofperformanceId,
                    accessAreaCode:
                      seletedTicketId == 436 ? "MOTFLC" : "VISA Pioneer",
                  };
                  console.log(`${JSON.stringify(postObjectTemp, null, 2)}`);
                  axios
                    .post(requests.addToCart, postObjectTemp)
                    .then((res) => {
                      console.log(`${JSON.stringify(res.data, null, 2)}`);
                      if (res.data.errorCode == "200") {
                        console.log(res.data);
                        const postObject = {
                          parkName: tourpack.attName,
                          customerName: values.bookCustomerName,
                          eMail: values.bookCustomerEmail,
                          contactNumber: values.bookMobileNumber,
                          agentId: b2bagentid,
                          agentUserId: agencyuser,
                          nofAdult: values.bookNofAdult,
                          nofChild: child_count,
                          startDate: traveldate,
                          endDate: traveldate,
                          timeSlot: res.data?.timeSlot,
                          shopCartId: res.data.shopCartId,
                          totalAmount: res.data.totalAmount,
                          performanceId: res.data.performanceId,
                          firstName: res.data.firstName,
                          lastName: res.data.lastName,
                          adultShopCartItemId: res.data.adultShopCartItemId,
                          childShopCartItemId: res.data.childShopCartItemId,
                          agentRefNumber: values.agentRefNumber,
                          accessAreaCode:
                            seletedTicketId == 436 ? "MOTFLC" : "VISA Pioneer",
                        };
                        encryptStorage.setItem("mofForBooking", postObject);

                        console.log(`${JSON.stringify(postObject, null, 2)}`);
                        history.push("/mof-ticket-confirmation");
                      } else {
                        Swal.fire({
                          icon: "error",
                          title: res.data.errorCode,
                          text: res.data.errorMessage,
                        });
                      }
                    })
                    .catch((error) => {
                      console.log(error);
                    });
                }

                if (
                  (Number(numOfAdult) > 0 || Number(numOfChild) > 0) &&
                  fName !== "" &&
                  lName !== ""
                ) {
                }
              } else {
                if (paymentMode == 1) {
                  // console.log("online pay");
                  const paymentData = {
                    paymentAgencyId: agency,
                    paymentAgencyUserId: agencyuser,
                    paymentB2cId: userId,
                    paymentCustomerName: values.bookCustomerName,
                    paymentRemarks: "Test Pay",
                    paymentAmount: total,
                    secretKey: requests.apiKey,
                    successUrl: `http://b2b.parmartours.com/${"openticket"}/payment-status`,
                    failureUrl: `https://b2b.parmartours.com/${"openticket"}/payment-status`,
                    temporaryBookingDto: submitdata,
                  };
                  console.log(
                    "Payment data",
                    `${JSON.stringify(paymentData, null, 2)}`
                  );

                  console.log("url", requests.setNetworkPaymentsDetail);
                  axios
                    .post(requests.setNetworkPaymentsDetail, paymentData)
                    .then((res) => {
                      console.log(res.data);
                      // window.location.replace(res.data.paymentUrl);
                    })
                    .catch((error) => {
                      console.log(error);
                    });
                } else {
                  setshowAlert(1);
                  console.log(`${JSON.stringify(submitdata, null, 2)}`);
                  axios
                    .post(requests.postBooking, submitdata, hConfig) //submitdata
                    .then((res) => {
                      console.log(`${JSON.stringify(res.data, null, 2)}`);
                      //no adult tkt
                      if (res.data.errorCode == 505) {
                        //no adult tkt
                        setshowAlert(2);
                      } else if (res.data.errorCode == 504) {
                        // no child tkt
                        setshowAlert(3);
                      } else if (res.data.errorCode == 303) {
                        setshowAlert(4);
                      } else if (res.data.errorCode == 100) {
                        setinsuffBal(1);
                        setshowAlert(0);
                      } else if (res.data.errorCode == 563) {
                        Swal.fire({
                          title: "Warning",
                          text: "Another User has logged in using your Login Credentials, Please Login Again.",
                          showDenyButton: false,
                          showCancelButton: false,
                          confirmButtonText: "Ok",
                          denyButtonText: `Don't save`,
                        }).then((result) => {
                          if (result.isConfirmed) {
                            // download_file(fileURL, filename);
                            // setshowAlert(0);
                            logout();
                            //window.location.replace(fileURL)
                          }
                        });
                      } else {
                        // if (res.data.bookingTickPdfPath == "") {
                        let ptNumber, invNumber;
                        ptNumber = res.data.bookNumber;
                        invNumber = res.data.xeroInvoiceNumber;

                        if (
                          res.data.bookingTickPdfPath != "" ||
                          res.data.bookingTickPdfPath != null
                        ) {
                          console.log("not equal to null");
                          let filename = res.data.bookingTickPdfPath;
                          const fileURL =
                            // "http://66.29.149.191:8080/filestorage/parmartour/images/" +
                            "https://b2b.parmartours.com/filestorage/" +
                            res.data.bookingTickPdfPath;
                          Swal.fire({
                            title: "Booking Confirmed",
                            text: "Thank You For Your Booking, press OK to download your e-Ticket",
                            showDenyButton: false,
                            showCancelButton: false,
                            confirmButtonText: "Ok",
                            denyButtonText: `Don't save`,
                          }).then((result) => {
                            if (result.isConfirmed) {
                              download_file(fileURL, filename);
                              setshowAlert(0);

                              //window.location.replace(fileURL)
                            }
                          });
                        } else {
                          let filename = `${ptNumber} - ${invNumber}.pdf`;
                          const fileURL =
                            // "http://66.29.149.191:8080/filestorage/parmartour/images/" +
                            "https://b2b.parmartours.com/filestorage/" +
                            `${ptNumber} - ${invNumber}.pdf`;
                          Swal.fire({
                            title: "Booking Confirmed",
                            text: "Thank You For Your Booking, press OK to download your e-Ticket",
                            showDenyButton: false,
                            showCancelButton: false,
                            confirmButtonText: "Ok",
                            denyButtonText: `Don't save`,
                          }).then((result) => {
                            if (result.isConfirmed) {
                              download_file(fileURL, filename);
                              setshowAlert(0);

                              //window.location.replace(fileURL)
                            }
                          });
                        }

                        setshowAlert(0);
                      }
                    })
                    .catch((err) => {
                      console.log(err);
                    });
                }
              }
            } else {
              let agencyId, bookedCreditLimit;
              if (showOption === true) {
                agencyId = agentIdForBook;
                bookedCreditLimit = UserCredit;
              } else {
                agencyId = agency;
              }
              const firstSubmitForBooking = {
                bookedByBackOffice: bookedByBackOffice,
                bookedCreditLimit: bookedCreditLimit,
                superAdminId: superAdminId,
                attractionId: values.attractionsId,
                ticketname: apiticketname.name,
                agencyId: agencyId,
                agencyUserId: agencyuser,
                customerMobileNumber: values.bookMobileNumber,
                customerName: values.bookCustomerName,
                customerEmail: values.bookCustomerEmail,
                bookingDate: values.bookTravellDate,
                //  bookTravellDate: traveldate,
                eventId: selectTimeSlotRes.eventId,
                eventName: selectTimeSlotRes.eventName,
                eventTypeId: selectTimeSlotRes.eventTypeId,
                resourceId: selectTimeSlotRes.resourceId,
                startDateTime: selectTimeSlotRes.startDateTime,
                endDateTime: selectTimeSlotRes.endDateTime,
                available: selectTimeSlotRes.available,
                status: selectTimeSlotRes.status,
                adultPrice: selectTimeSlotRes.adultPrice,
                childPrice: selectTimeSlotRes.childPrice,
                nofAdult: values.bookNofAdult,
                nofChild: child_count,
                nofInfant: "",
                agentServiceTicketTypes:
                  selectTimeSlotRes.agentServiceTicketTypes,
                totalPrice: total,
                bookPaymentMode: paymentMode,
                secretKey: requests.apiKey,
                errorMessage: splError,
                agentRefNumber: values.agentRefNumber,
              };

              encryptStorage.setItem(
                "firstSubmitForBooking",
                firstSubmitForBooking
              );

              console.log(`${JSON.stringify(firstSubmitForBooking, null, 2)}`);

              // console.log("firstSubmitForBooking", firstSubmitForBooking);

              // for booking dated ticket (from api)
              // console.log("dated Ticket");
              // here need to check payment mode is online(1) or credit(2)
              if (paymentMode == 1) {
                //  console.log("Call Payment url");
                //here registerfor payment and get payment registeri id
                // console.log("Online Payment dated Ticket");

                //getPaymentGatewayUrl
                axios
                  .post(requests.saveBurjTicket, firstSubmitForBooking)
                  // .post(
                  //   "https://parmartours.com:8443/parmartour-test/v1.0/setStripePaymentsDetail",
                  //   paymentData
                  // )
                  .then((response) => {
                    //200 for success
                    if (response.data.errCode == "200") {
                      console.log(`${JSON.stringify(response.data, null, 2)}`);

                      encryptStorage.setItem("url", response.data);

                      history.push("/api-tkt-confirmation-page");
                    } else {
                      if (
                        response.data.errMessage.includes(
                          "Credit Limit only available"
                        )
                      ) {
                        Swal.fire(`${response.data.errCode} - API Error`);
                      }
                    }
                  })
                  .catch((error) => {
                    console.log(error);
                  });
              } else {
                axios
                  .post(requests.saveBurjTicket, firstSubmitForBooking)
                  .then((response) => {
                    if (response.data.errCode == "200") {
                      console.log(`${JSON.stringify(response.data, null, 2)}`);
                      encryptStorage.setItem("url", response.data);
                      history.push("/api-tkt-confirmation-page");
                    } else {
                      if (
                        response.data.errMessage.includes(
                          "Credit Limit only available"
                        )
                      ) {
                        Swal.fire(`${response.data.errCode} - API Error`);
                      }
                    }
                  })
                  .catch((error) => {
                    console.log(error);
                  });
              }
            }
          } else {
            //user process
            if (tourpack.attConnectWithApi == true) {
              if (OpenTicket == 2) {
                const firstSubmitForBooking = {
                  attractionId: values.attractionsId,
                  agencyId: agency,
                  agencyUserId: agencyuser,
                  customerName: values.bookCustomerName,
                  customerEmail: values.bookCustomerEmail,
                  //bookingDate: values.bookTravellDate,
                  ticketname: apiticketname.name,
                  bookTravellDate: traveldate,
                  eventId: selectTimeSlotRes.eventId,
                  eventName: selectTimeSlotRes.eventName,
                  eventTypeId: selectTimeSlotRes.eventTypeId,
                  resourceId: selectTimeSlotRes.resourceId,
                  startDateTime: selectTimeSlotRes.startDateTime,
                  endDateTime: selectTimeSlotRes.endDateTime,
                  available: selectTimeSlotRes.available,
                  status: selectTimeSlotRes.status,
                  adultPrice: selectTimeSlotRes.adultPrice,
                  childPrice: selectTimeSlotRes.childPrice,
                  nofAdult: values.bookNofAdult,
                  nofChild: child_count,
                  nofInfant: "",
                  agentServiceTicketTypes:
                    selectTimeSlotRes.agentServiceTicketTypes,
                  totalPrice: total,
                  bookPaymentMode: paymentMode,
                  secretKey: requests.apiKey,
                  currencyCode: "AED",
                  errorMessage: splError,
                  agentRefNumber: values.agentRefNumber,
                };
                // console.log("Api Ticket Paytnmentsdsdsdsds");
                // console.log("Api Ticket Paytnmentsdsdsdsds", apiTicketPayment);
                encryptStorage.setItem(
                  "firstSubmitForBooking",
                  firstSubmitForBooking
                );

                console.log("firstSubmitForBooking", firstSubmitForBooking);
                //getPaymentGatewayUrl
                axios
                  .post(requests.saveBurjTicket, firstSubmitForBooking)
                  // .post(
                  //   "https://parmartours.com:8443/parmartour-test/v1.0/setStripePaymentsDetail",
                  //   paymentData
                  // )
                  .then((response) => {
                    console.log("RESPONCE DATA", response.data);
                    encryptStorage.setItem("url", response.data);

                    history.push("/dated-ticket-payment");
                  })
                  .catch((error) => {
                    console.log(error);
                  });
              } else {
                const paymentData = {
                  paymentAgencyId: agency,
                  paymentAgencyUserId: agencyuser,
                  paymentB2cId: userId,
                  paymentCustomerName: values.bookCustomerName,
                  paymentRemarks: "Test Pay",
                  paymentAmount: total,
                  secretKey: requests.apiKey,
                  successUrl: `https://b2b.parmartours.com/${"openticket"}`,
                  failureUrl: `https://b2b.parmartours.com/${"openticket"}`,
                  temporaryBookingDto: submitdata,
                };
                //history.push("/login-page");
                // console.log("Submit Data", paymentData);
                axios
                  .post(requests.setBookingIp, paymentData)
                  // .post(  setStripePaymentsDetail
                  //   "https://parmartours.com:8443/parmartour-test/v1.0/setStripePaymentsDetail",
                  //   paymentData
                  // )
                  .then((response) => {
                    // encryptStorage.setItem("url", response.data);
                    // setshowAlert(0);
                    // history.push("/online-payment-port");
                    // setshowAlert(0);
                    Swal.fire("Something went wring , please try again later");
                  })
                  .catch((error) => {
                    console.log(error);
                    setshowAlert(0);
                  });
              }
            } else {
              const paymentData = {
                paymentAgencyId: agency,
                paymentAgencyUserId: agencyuser,
                paymentB2cId: userId,
                paymentCustomerName: values.bookCustomerName,
                paymentRemarks: "Test Pay",
                paymentAmount: total,
                secretKey: requests.apiKey,
                successUrl: `https://b2b.parmartours.com/${"openticket"}`,
                failureUrl: `https://b2b.parmartours.com/${"openticket"}`,
                temporaryBookingDto: submitdata,
              };
              //history.push("/login-page");
              //  console.log("Submit Data", paymentData); setBookingIp
              axios
                .post(requests.setBookingIp, paymentData)
                // .post(  setStripePaymentsDetail
                //   "https://parmartours.com:8443/parmartour-test/v1.0/setStripePaymentsDetail",
                //   paymentData
                // )
                .then((response) => {
                  // encryptStorage.setItem("url", response.data);
                  // setshowAlert(0);
                  // history.push("/online-payment-port");
                  // setshowAlert(0);
                  Swal.fire("Something went wring , please try again later");
                })
                .catch((error) => {
                  console.log(error);
                  setshowAlert(0);
                });
            }
          }
          // console.log("logged In");
        } else {
          //user process

          history.push("/login-page");
        }
      } else {
        //total err
        settotalerr(true);
      }
    } else if (values.pill == 2) {
      // pill =2 Add To Cart
      // for cart

      let uType;
      if (submitdata.bookB2bId != 0) {
        uType = 1;
      } else if (submitdata.bookB2bUserId != 0) {
        uType = 2;
      } else if (submitdata.bookB2cId) {
        uType = 3;
      }
      const cartinfoData = {
        CartInfoId: "",
        userType: uType, // 1 - Agent, 2 - Agent User, 3 - B2C user
        b2bId: submitdata.bookB2bId,
        b2bUserId: submitdata.bookB2bUserId,
        b2cId: submitdata.bookB2cId,
        passengerName: submitdata.bookCustomerName,
        emailId: submitdata.bookCustomerEmail,
        contactNumber: submitdata.bookMobileNumber,
        // travelDate: submitdata.bookTravellDate,
        bookTravellDate: traveldate,
        attractionId: submitdata.attractionsId,
        ticketTypeId: submitdata.ticketTypeId,
        nofAdult: submitdata.bookNofAdult,
        nofChild: submitdata.bookNofChild,
        bookAdultPrice: adultfare,
        bookChildprice: childfare,
        bookTotal: total,
        attName: tourpack.attName,
        tktName: ticketName,
        comboOfferId: "",
        agentRefNumber: submitdata.agentRefNumber,
        secretKey: requests.apiKey,
        platformId: 1,
      };
      // console.log(`${JSON.stringify(cartinfoData, null, 2)}`);

      let loacErr = false;

      if (id == 147) {
        console.log("Mof Attraction");

        if (!values.moftimeSlot && !values.mofperformanceId) {
          Swal.fire({
            icon: "error",
            title: "Error",
            text: "Ensure Your Time slot is selected",
          });
        } else {
          const postObjectTemp = {
            // tktid: seletedTicketId,

            firstName: fName,
            lastName: lName,
            nofAdult: values.bookNofAdult,
            nofChild: child_count,
            timeSlot: values.moftimeSlot,
            performanceId: values.mofperformanceId,
            accessAreaCode: seletedTicketId == 436 ? "MOTFLC" : "VISA Pioneer",
          };

          console.log(`${JSON.stringify(postObjectTemp, null, 2)}`);

          axios
            .post(requests.addToCart, postObjectTemp)
            .then((res) => {
              console.log(`${JSON.stringify(res.data, null, 2)}`);
              if (res.data.errorCode == "200") {
                console.log(res.data);
                const postObject = {
                  tktname:
                    seletedTicketId == 480 ? "Pioneer Pass" : "Entry Ticket",
                  parkName: tourpack.attName,
                  customerName: values.bookCustomerName,
                  eMail: values.bookCustomerEmail,
                  contactNumber: values.bookMobileNumber,
                  agentId: b2bagentid,
                  agentUserId: agencyuser,
                  nofAdult: values.bookNofAdult,
                  nofChild: child_count,
                  startDate: traveldate,
                  endDate: traveldate,
                  timeSlot: res.data?.timeSlot,
                  shopCartId: res.data.shopCartId,
                  totalAmount: res.data.totalAmount,
                  performanceId: res.data.performanceId,
                  firstName: res.data.firstName,
                  lastName: res.data.lastName,
                  adultShopCartItemId: res.data.adultShopCartItemId,
                  childShopCartItemId: res.data.childShopCartItemId,
                  agentRefNumber: values.agentRefNumber,
                  accessAreaCode:
                    seletedTicketId == 436 ? "MOTFLC" : "VISA Pioneer",
                };
                encryptStorage.setItem("mofForBooking", postObject);

                console.log(`${JSON.stringify(postObject, null, 2)}`);
                doRefresh((prev) => prev + 1);
                Swal.fire(
                  "Thank You!",
                  "Your Order Has Been Added to Cart!",
                  "success"
                );
              } else {
                Swal.fire({
                  icon: "error",
                  title: res.data.errorCode,
                  text: res.data.errorMessage,
                });
              }
            })
            .catch((error) => {
              console.log(error);
            });
        }

        if (
          (Number(numOfAdult) > 0 || Number(numOfChild) > 0) &&
          fName !== "" &&
          lName !== ""
        ) {
        }

        // setshowAlert(1);
        // axios
        //   .post(requests.confirmTicket, postObject)
        //   .then((res) => {
        //     if (res.data.errorCode == "200") {
        //       console.log(res.data);
        //       let filename = res.data.pdfPath;
        //       const fileURL =
        //         // "http://66.29.149.191:8080/filestorage/parmartour/images/" +
        //         "https://b2b.parmartours.com/filestorage/" +
        //         res.data.pdfPath;
        //       Swal.fire({
        //         title: "Booking Confirmed",
        //         text: "Thank You For Your Booking, press OK to download your e-Ticket",
        //         showDenyButton: false,
        //         showCancelButton: false,
        //         confirmButtonText: "Ok",
        //         denyButtonText: `Don't save`,
        //       }).then((result) => {
        //         if (result.isConfirmed) {
        //           download_file(fileURL, filename);
        //           setshowAlert(0);

        //           //window.location.replace(fileURL)
        //         }
        //       });
        //     } else {
        //       Swal.fire({
        //         icon: "error",
        //         title: res.data.errorCode,
        //         text: res.data.errorMessage,
        //       });
        //     }
        //   })
        //   .catch((error) => {
        //     console.log(error);
        //   });
      } else if (loacErr == false) {
        axios
          .post(requests.setCartInfo, cartinfoData)
          .then((response) => {
            // console.log("Cart info from server");
            console.log(`${JSON.stringify(response.data, null, 2)}`);
            doRefresh((prev) => prev + 1);
            Swal.fire(
              "Thank You!",
              "Your Order Has Been Added to Cart!",
              "success"
            );
          })
          .catch((error) => {
            console.log(error);
          });
      }
    } else if (values.pill == 3) {
      history.push("/login-page");
    } else if (values.pill == 4) {
      history.push("/login-page");
    }
  };

  const successAlert = (title, text, url, boolValue) => {
    Swal.fire({
      title: title,
      text: text,
      imageUrl: url,
      imageWidth: 400,
      imageHeight: 200,
      imageAlt: "Custom image",
      showCancelButton: false, // There won't be any cancel button
      showConfirmButton: boolValue, // There won't be any confirm button
    });
  };

  if (showAlert == 1) {
    successAlert(
      "Please Wait",
      "Processing",
      publicUrl + "assets/img/formsubmit.gif"
      //publicUrl publicUrl + "assets/img/others/about1.jpeg"
    );
  }
  if (showAlert == 2) {
    successAlert("Ticket Not Avilable", "Ticket Not Available");
  }

  if (showAlert == 3) {
    successAlert("Ticket Not Avilable", "Child  Ticket Not Available");
  }
  if (showAlert == 4) {
    successAlert("Technical Problem", "Something went wrong");
  }
  if (showAlert == 5) {
    successAlert(
      "Another User has logged in using your Login Credentials, Please Login Again."
    );
  }
  /* Helper function */
  function download_file(fileURL, fileName) {
    // for non-IE
    if (!window.ActiveXObject) {
      var save = document.createElement("a");
      save.href = fileURL;
      save.target = "_blank";
      var filename = fileURL.substring(fileURL.lastIndexOf("/") + 1);
      save.download = fileName || filename;
      if (
        navigator.userAgent.toLowerCase().match(/(ipad|iphone|safari)/) &&
        navigator.userAgent.search("Chrome") < 0
      ) {
        document.location = save.href;
        // window event not working here
      } else {
        var evt = new MouseEvent("click", {
          view: window,
          bubbles: true,
          cancelable: false,
        });
        save.dispatchEvent(evt);
        (window.URL || window.webkitURL).revokeObjectURL(save.href);
      }
    }

    // for IE < 11
    else if (!!window.ActiveXObject && document.execCommand) {
      var _window = window.open(fileURL, "_blank");
      _window.document.close();
      _window.document.execCommand("SaveAs", true, fileName || fileURL);
      _window.close();
    }
    history.goBack();
  }

  const enabledDates = [
    new Date("2024-03-24"),
    new Date("2024-03-24"),
    new Date("2024-03-25"),
    new Date("2024-03-26"),
    new Date("2024-03-27"),
    new Date("2024-03-28"),
    new Date("2024-03-29"),
    new Date("2024-03-30"),
    new Date("2024-03-31"),
    new Date("2024-04-01"),
    new Date("2024-04-02"),
    new Date("2024-04-03"),
    new Date("2024-04-04"),
    new Date("2024-04-05"),
    new Date("2024-04-06"),
    new Date("2024-04-07"),
    new Date("2024-04-08"),
    new Date("2024-04-09"),
    new Date("2024-04-10"),
    new Date("2024-04-11"),
    new Date("2024-04-12"),
    new Date("2024-04-13"),
    new Date("2024-04-14"),

    // Add more dates as needed
  ];

  return (
    <>
      <TopNav refresh={refresh} />
      <DynamicBanner
        imgUrl={requests.imgpath + tourpack.attBannerImage}
        title={tourpack.attName}
      />
      <div className="tour-details-area mg-top--70">
        <div className="tour-details-gallery">
          <div className="container-bg bg-dark-blue">
            <div className="container">
              <div className="row">
                <div className="col-xl-3 col-lg-4">
                  <div className="details">
                    <p className="location mb-0">
                      <i className="fa fa-map-marker" />
                      {tourpack.attCity}
                    </p>
                    <h4 className="title">{tourpack.attName}</h4>
                  </div>
                </div>
                <div className="col-xl-9 col-lg-8">
                  <div className="book-list-warp">
                    <p className="book-list-content">
                      Just booked! Get your spot before it's too late.
                    </p>
                    <div className="tp-price-meta">
                      {/* <p>Price</p>
                      <h2>
                        {LoggedIn == 1
                          ? tourpack.b2bAdultPrice
                          : tourpack.b2cAdultPrice}
                        &nbsp;&nbsp;&nbsp;
                        <small>AED</small>
                      </h2> */}

                      {login === "yes" && (
                        <Row>
                          <Col>
                            <p>
                              Adult Price <h2>{tourpack.adultPrice}</h2>
                            </p>{" "}
                            &nbsp;&nbsp;&nbsp;<small>AED</small>
                          </Col>
                          <Col>
                            <p>
                              Child Price <br />
                              <h2>{tourpack.childPrice}</h2>
                            </p>{" "}
                            &nbsp;&nbsp;&nbsp;<small>AED</small>
                          </Col>
                        </Row>
                      )}
                    </div>
                  </div>
                  <ul className="tp-list-meta border-tp-solid">
                    {isBannerLoading ? (
                      <>Loading ...</>
                    ) : (
                      <>
                        {bannerAddOn.map((banner, index) => (
                          <li key={index}>
                            <i className={banner.addonIcon} />{" "}
                            {banner.addonType} &nbsp; - &nbsp;
                            {banner.addonValue}
                          </li>
                        ))}
                      </>
                    )}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="container">
          {isLoading ? (
            <>Loading...</>
          ) : (
            <>
              <Row>
                <Col sm={8}>
                  <br />
                  {showHolydays != "" && (
                    <>
                      <h4 className="single-page-small-title">
                        Black Out Days
                      </h4>
                      &nbsp;&nbsp;&nbsp;&nbsp;
                      <ul>
                        {showHolydays.map((item, index) => (
                          <li key={index}>
                            <div
                              style={{
                                marginTop: "5px",
                              }}
                              key={item.attractionHolidayId}
                            >
                              <label
                                style={{
                                  color: "red",
                                }}
                              >
                                From
                              </label>
                              &nbsp;&nbsp;
                              <b
                                style={{
                                  fontSize: "20px",
                                }}
                              >
                                {" "}
                                {item.startDate}
                              </b>{" "}
                              &nbsp;&nbsp;
                              <label
                                style={{
                                  color: "red",
                                }}
                              >
                                To
                              </label>
                              &nbsp;&nbsp;
                              <b
                                style={{
                                  fontSize: "20px",
                                }}
                              >
                                {item.endDate}
                              </b>
                            </div>
                          </li>
                        ))}
                      </ul>
                    </>
                  )}
                  <CardBody>
                    <Carousel
                      // showArrows={true}
                      // showThumbs={true}
                      // transitionTime={10000}
                      autoPlay={true}
                      infiniteLoop={true}
                    >
                      {tourpack.filesStorage
                        .filter((item) => item.platformId === 1)
                        ?.map((tourImage, index) => (
                          // slice(0,10 )
                          <div
                            className="tp-gallery-item col-lg-12 "
                            // className="carousal-img-size"
                            key={index}
                          >
                            <div className="tp-gallery-item-img">
                              <a href="#" data-effect="mfp-zoom-in">
                                <img
                                  height="300"
                                  width="100%"
                                  src={requests.imgpath + tourImage.fileName}
                                  alt="image"
                                />
                              </a>
                            </div>
                          </div>
                        ))}
                    </Carousel>

                    <div className="tour-details-wrap">
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                          width: "100%",
                        }}
                      >
                        <h4 className="single-page-small-title">
                          {tourpack.attName}
                        </h4>
                        {id === "15" && (
                          <div>
                            <a
                              href="https://b2b.parmartours.com/filestorage/EEG-product-description.pdf"
                              target="_blank"
                              rel="noopener noreferrer"
                              style={{
                                color: "red",
                                fontSize: "20px",
                                cursor: "pointer",
                                fontWeight: "bold",
                              }}
                            >
                              {" "}
                              Product Description
                            </a>
                          </div>
                        )}
                      </div>

                      <p>
                        {" "}
                        <div
                          dangerouslySetInnerHTML={{
                            __html: tourpack.attDescription,
                          }}
                        />
                      </p>
                    </div>
                  </CardBody>
                </Col>

                <Col sm={4}>
                  <div className="fixed-content">
                    <Card>
                      <CardHeader>
                        {" "}
                        <h3
                          className="single-page-small-title"
                          // onClick={() => {
                          //   Swal.fire("Error", "Token expired", "error");
                          // }}
                        >
                          Book Now
                        </h3>
                      </CardHeader>
                      <CardBody>
                        <Form>
                          {LoggedIn == 1 ? (
                            <>
                              {userdata.b2b == true ||
                              userdata.b2bUser == true ? (
                                <>
                                  {/* {
                                  userdata.agency == ""?<>{console.log("Nilllll")}</>:<></>
                                } */}{" "}
                                  {userdata.b2b == true &&
                                  userdata.agency.superAdmin == true ? (
                                    <>
                                      <Field
                                        name="BookForAgent"
                                        component={
                                          RenderField.RendercheckboxField
                                        }
                                        label="Book For Agent"
                                        onChange={(e) => {
                                          setShowOption(e.target.checked);
                                          props.change("ticketTypeId", null);
                                          props.change(
                                            "agentIdForBookfromSuperAdmin",
                                            null
                                          );
                                          setAgentIdAndResetTkt(0);
                                        }}
                                      />
                                      {showOption === true ? (
                                        <>
                                          {/* <label>Select Agent</label> */}
                                          {/* <Select
                                            options={agentList}
                                            onChange={(e) =>
                                              setagentIdForBook(e.value)
                                            }
                                          /> */}

                                          <Field
                                            name="agentIdForBookfromSuperAdmin"
                                            label="Select Agent"
                                            options={agentList}
                                            onChange={(e) => {
                                              setAgentIdAndResetTkt(e);
                                              props.change(
                                                "ticketTypeId",
                                                null
                                              );
                                            }}
                                            component={
                                              RenderField.RenderSelectField
                                            }
                                          />

                                          <span
                                            style={{
                                              color: "red",
                                            }}
                                          >
                                            Available Balance:-
                                            {getagentBalance(agentIdForBook)}
                                          </span>

                                          {agentSelErr == true ? (
                                            <Alert color="primary">
                                              Select Agnet{" "}
                                            </Alert>
                                          ) : null}

                                          <br />
                                          {agency === 268 && (
                                            <Row>
                                              <Col>
                                                <Field
                                                  name="bookedCredit"
                                                  component="input"
                                                  type="radio"
                                                  value="1"
                                                  checked="checked"
                                                  onChange={(e) =>
                                                    setUserCredit(
                                                      e.target.value
                                                    )
                                                  }
                                                />{" "}
                                                Agent Credit
                                              </Col>

                                              <Col>
                                                <Field
                                                  name="bookedCredit"
                                                  component="input"
                                                  type="radio"
                                                  value="2"
                                                  onChange={(e) =>
                                                    setUserCredit(
                                                      e.target.value
                                                    )
                                                  }
                                                />
                                                Super User Credit
                                              </Col>
                                            </Row>
                                          )}
                                        </>
                                      ) : (
                                        <></>
                                      )}
                                    </>
                                  ) : null}
                                </>
                              ) : (
                                <></>
                              )}
                            </>
                          ) : null}

                          <Field
                            name="attractionsId"
                            type="hidden"
                            icon=""
                            component={RenderField.RenderTextField}
                          />
                          {id == "147" ? (
                            <Row>
                              <Col>
                                <Field
                                  name="firstName"
                                  type="text"
                                  label="First Name"
                                  onChange={(e) => setfName(e.target.value)}
                                  component={RenderField.RenderTextField}
                                />
                              </Col>
                              <Col>
                                <Field
                                  name="lastName"
                                  type="text"
                                  label="Last Name"
                                  onChange={(e) => setlName(e.target.value)}
                                  component={RenderField.RenderTextField}
                                />
                              </Col>
                            </Row>
                          ) : (
                            <Field
                              name="bookCustomerName"
                              type="text"
                              placeholder="Optional"
                              label="Lead Passenger Name"
                              component={RenderField.RenderTextField}
                            />
                          )}

                          <Field
                            name="bookCustomerEmail"
                            type="email"
                            placeholder="Optional"
                            label="Email Address"
                            onChange={(e) => setuserEmail(e.target.value)}
                            component={RenderField.RenderTextField}
                          />
                          <Field
                            name="bookMobileNumber"
                            type="text"
                            label="Contact Number"
                            component={RenderField.RenderTextField}
                          />

                          {tourpack.openTicketOption == true &&
                            tourpack.attConnectWithApi && (
                              <>
                                {LoggedIn == 1 ? (
                                  <>
                                    {userdata.b2b == true ||
                                    userdata.b2bUser == true ? (
                                      <>
                                        {" "}
                                        <div className="single-widget-search-input-title">
                                          <i className=" " /> Select Ticket Type
                                        </div>
                                        <Row>
                                          <Col>
                                            <Field
                                              name="openTkt"
                                              component="input"
                                              type="radio"
                                              value="1"
                                              onChange={(e) =>
                                                setOpenTicket(e.target.value)
                                              }
                                            />{" "}
                                            Open Ticket
                                          </Col>
                                          <Col>
                                            <Field
                                              name="openTkt"
                                              component="input"
                                              type="radio"
                                              value="2"
                                              onChange={(e) =>
                                                setOpenTicket(e.target.value)
                                              }
                                            />{" "}
                                            Dated Ticket
                                          </Col>
                                        </Row>
                                      </>
                                    ) : null}
                                  </>
                                ) : null}
                              </>
                            )}

                          {tourpack.attConnectWithApi == true ? (
                            <>
                              {LoggedIn == 1 &&
                              tourpack.openTicketOption === true ? (
                                <>
                                  {userdata.b2b == true ||
                                  userdata.b2bUser == true ? (
                                    <>
                                      {OpenTicket == 1 || OpenTicket == 2 ? (
                                        <>
                                          <Field
                                            name="ticketTypeId"
                                            type="select"
                                            customfeild={
                                              OpenTicket == 1
                                                ? tkttypeTemp
                                                : apiTicketType
                                            }
                                            label="Ticket Type"
                                            onChange={(e) =>
                                              gettktDisPrice(e.target.value)
                                            }
                                            component={
                                              RenderField.renderOptionField
                                            }
                                          />
                                          {tktAvailability == 1 ? (
                                            <Alert color="primary">
                                              {servserRes}
                                            </Alert>
                                          ) : null}
                                        </>
                                      ) : (
                                        <></>
                                      )}
                                    </>
                                  ) : (
                                    <>
                                      <Field
                                        name="ticketTypeId"
                                        type="select"
                                        customfeild={apiTicketType}
                                        label="Ticket Type"
                                        onChange={(e) =>
                                          gettktDisPrice(e.target.value)
                                        }
                                        component={
                                          RenderField.renderOptionField
                                        }
                                      />
                                      {tktAvailability == 1 ? (
                                        <Alert color="primary">
                                          {servserRes}
                                        </Alert>
                                      ) : null}
                                    </>
                                  )}
                                </>
                              ) : (
                                <>
                                  <Field
                                    name="ticketTypeId"
                                    type="select"
                                    customfeild={apiTicketType}
                                    label="Ticket Type"
                                    onChange={(e) =>
                                      gettktDisPrice(e.target.value)
                                    }
                                    component={RenderField.renderOptionField}
                                  />
                                  {tktAvailability == 1 ? (
                                    <Alert color="primary">{servserRes}</Alert>
                                  ) : null}
                                </>
                              )}
                            </>
                          ) : (
                            <>
                              <Field
                                name="ticketTypeId"
                                type="select"
                                customfeild={tkttypeTemp}
                                label="Ticket Type"
                                onChange={(e) => gettktDisPrice(e.target.value)}
                                component={RenderField.renderOptionField}
                              />
                            </>
                          )}

                          <Row>
                            <Col>
                              <Field
                                name="bookNofAdult"
                                type="text"
                                label="Adult"
                                predefindvalue={
                                  numOfAdult === 0 ? "" : numOfAdult
                                }
                                disabled={
                                  adultClicked === true &&
                                  fullTicketData?.errorCode === 505 &&
                                  true
                                }
                                onClick={handleFieldClickAdult}
                                onChange={(e) => {
                                  setnumOfAdult(e.target.value);
                                  setmofSeletedTicket();
                                  setmofCartData();
                                }}
                                // onChange={(e) =>
                                //   amountCalculation(e.target.value)
                                // }
                                component={RenderField.RenderTextField}
                              />
                              {adultClicked === true &&
                                fullTicketData?.errorCode === 505 && (
                                  <span
                                    style={{ color: "red", fontSize: "12px" }}
                                  >
                                    Ticket Not Available
                                  </span>
                                )}
                              {showTktFare == 1 ? (
                                LoggedIn == 1 ? (
                                  <>
                                    {userdata.b2b == true ||
                                    userdata.b2bUser == true ? (
                                      <>
                                        {" "}
                                        {adultfare}
                                        <del>
                                          <small>{actAdultfare}</small>
                                        </del>
                                      </>
                                    ) : (
                                      adultfare
                                    )}
                                  </>
                                ) : (
                                  adultfare
                                )
                              ) : null}{" "}
                              <br />
                              <span style={{ color: "red", fontSize: "12px" }}>
                                {" "}
                                {adultExpiry}
                              </span>
                            </Col>
                            <Col>
                              <Field
                                name="bookNofChild"
                                type="text"
                                label="Child"
                                predefindvalue={
                                  numOfChild === 0 ? "" : numOfChild
                                }
                                // disabled={
                                //   (childClicked === true &&
                                //     fullTicketData?.errorCode === 504 &&
                                //     true) ||
                                //   (adultClicked === true &&
                                //     fullTicketData?.errorCode === 505 &&
                                //     true) ||
                                //   (childClicked === true &&
                                //     fullTicketData?.errorCode === 505 &&
                                //     true)
                                // }

                                disabled={
                                  ((childClicked === true &&
                                    fullTicketData?.errorCode === 504) ||
                                    seletedTicketId == 480) &&
                                  true
                                }
                                onClick={handleFieldClickChild}
                                onChange={(e) => {
                                  setnumOfChild(e.target.value);
                                  setmofSeletedTicket();
                                  setmofCartData();
                                }}
                                component={RenderField.RenderTextField}
                              />
                              {childClicked === true &&
                                fullTicketData?.errorCode === 504 && (
                                  <span
                                    style={{ color: "red", fontSize: "12px" }}
                                  >
                                    Child Ticket Not Available
                                  </span>
                                )}
                              {seletedTicketId == 480 && (
                                <span
                                  style={{ color: "red", fontSize: "12px" }}
                                >
                                  Child Ticket Not Available
                                </span>
                              )}
                              {/* {childClicked === true &&
                                fullTicketData?.errorCode === 505 && (
                                  <span
                                    style={{ color: "red", fontSize: "12px" }}
                                  >
                                    Ticket Not Available
                                  </span>
                                )} */}
                              {showTktFare == 1 ? (
                                LoggedIn == 1 ? (
                                  <>
                                    {userdata.b2b == true ||
                                    userdata.b2bUser == true ? (
                                      <>
                                        {childfare}
                                        <del>
                                          <small>{actChildfare}</small>
                                        </del>
                                      </>
                                    ) : (
                                      childfare
                                    )}
                                  </>
                                ) : (
                                  childfare
                                )
                              ) : null}{" "}
                              <br />
                              <span style={{ color: "red", fontSize: "12px" }}>
                                {childExpiry}{" "}
                              </span>
                              {/* {childExpiry != "" && (
                                <>
                                  <i
                                    className="fa fa-info-circle"
                                    aria-hidden="true"
                                    style={{
                                      cursor: "pointer",
                                    }}
                                    id="childExpiry"
                                  />
                                  <Tooltip
                                    placement="right"
                                    isOpen={childTooltipOpen}
                                    target="childExpiry"
                                    toggle={childtoggle}
                                  >
                                    {childExpiry}
                                  </Tooltip>
                                </>
                              )} */}
                            </Col>
                            {tourpack.attConnectWithApi && (
                              <Col>
                                <Field
                                  name="bookNofInfant"
                                  type="text"
                                  label="Infants"
                                  // onChange={(e) => setnumOfChild(e.target.value)}
                                  component={RenderField.RenderTextField}
                                />
                              </Col>
                            )}
                          </Row>
                          <br />

                          {/* <Field
                            name="bookTravellDate"
                            type="date"
                            label="Travel Date"
                            onChange={(e) => checkTimeSlot(e.target.value)}
                            component={RenderField.RenderDateField}
                            className="form-control"
                          /> */}

                          <Field
                            name="bookTravellDate"
                            type="date"
                            label="Travel Date"
                            minDate={new Date()}
                            holydays={holydays}
                            // enabledDates={(id == '2' || id == '8') && enabledDates}
                            onChange={(e) => checkTimeSlot(e)}
                            component={RenderField.renderDatePicker}
                            className="form-control"
                          />
                          <br />
                          {setEventIdErr && (
                            <Alert color="primary">
                              Select Your Ticket Type
                            </Alert>
                          )}

                          {loaderForSlot && (
                            <>
                              <div className="container">
                                <div className="row justify-content-center">
                                  <div className="loader"></div>
                                </div>
                              </div>
                            </>
                          )}

                          {slot && (
                            <>
                              <div className="single-widget-search-input-title">
                                Available Slots
                              </div>
                              <br />
                              <div className="row" style={{ padding: "5px" }}>
                                {timeSlot != "" ? (
                                  <>
                                    {timeSlot.map((timeslot, index) => (
                                      <div
                                        className="col-lg-6 col-sm-6"
                                        // style={{
                                        //   padding: "5px",
                                        //   width: "250px",
                                        // }}
                                        key={index}
                                      >
                                        <>
                                          <div>
                                            <Button
                                              type="button"
                                              color={
                                                timeslot.available <= 0
                                                  ? "danger"
                                                  : timeslot.available <
                                                      Number(numOfAdult) +
                                                        Number(numOfChild) ||
                                                    timeslot.available < 20
                                                  ? "warning"
                                                  : "success"
                                              }
                                              // disabled={
                                              //   timeslot.available <= 0
                                              //     ? "true"
                                              //     : "false"
                                              // }
                                              value={timeslot.startDateTime}
                                              onClick={() =>
                                                selectedTimeSlot(
                                                  timeslot.eventID,
                                                  timeslot.eventName,
                                                  timeslot.startDateTime,
                                                  timeslot.endDateTime,
                                                  timeslot.eventTypeID,
                                                  timeslot.resourceID,
                                                  timeslot.available,
                                                  timeslot.status
                                                )
                                              }
                                            >
                                              {timeslot.startDateTime.slice(
                                                11,
                                                -3
                                              ) +
                                                "-" +
                                                timeslot.endDateTime.slice(
                                                  11,
                                                  -3
                                                ) +
                                                "(" +
                                                timeslot.available +
                                                ")"}
                                              {timeslot.startDateTime ===
                                                bookTimeSlot &&
                                              timeslot.available >=
                                                Number(numOfAdult) +
                                                  Number(numOfChild) ? (
                                                <>
                                                  <i
                                                    className="fa fa-check"
                                                    aria-hidden="true"
                                                  />
                                                </>
                                              ) : null}
                                            </Button>
                                          </div>
                                        </>
                                        <br />
                                      </div>
                                    ))}
                                  </>
                                ) : (
                                  <>
                                    <div className="container">
                                      <div className="row justify-content-center">
                                        <div className="single-widget-search-input-title">
                                          No Slot Available
                                        </div>
                                      </div>
                                    </div>
                                  </>
                                )}

                                <br />
                              </div>
                            </>
                          )}
                          {mofAvailable !== null && (
                            <div className="container">
                              <div className="row justify-content-center">
                                <div className="single-widget-search-input-title">
                                  {mofAvailable}{" "}
                                  {mofAvailable !==
                                    "Time Slot Not Available Please Select Another Date" && (
                                    <div className="loader"></div>
                                  )}
                                </div>
                              </div>
                            </div>
                          )}

                          {seletedTicketId == 480 ? (
                            <div>This ticket used for full day</div>
                          ) : (
                            mofTimeSlot && (
                              <div className="row" style={{ padding: "5px" }}>
                                {/* {(Number(numOfAdult) > 0 ||
                                  Number(numOfChild) > 0) &&
                                  (fName === "" ||
                                    lName === "" ||
                                    userEmail === "") && (
                                    <div
                                      style={{
                                        padding: "20px",
                                      }}
                                    >
                                      <h4>
                                        Please Ensure at least one of Adult
                                        count or Child count, First Name, and
                                        Last Name And Email
                                      </h4>
                                    </div>
                                  )} */}

                                {}

                                {mofTimeSlot.slotAvailability?.map(
                                  (item, index) => (
                                    <div
                                      className="col-lg-6 col-sm-6"
                                      key={index}
                                    >
                                      <div
                                        style={{
                                          marginTop: "10px",
                                        }}
                                      >
                                        <Button
                                          type="button"
                                          color="success"
                                          // disabled={
                                          //   (((Number(numOfAdult) > 0 ||
                                          //     Number(numOfChild) > 0) &&
                                          //     fName == "") ||
                                          //     lName == "" ||
                                          //     userEmail === "") &&
                                          //   true
                                          // }
                                          onClick={() => getmofSelected(item)}
                                        >
                                          {item.dateTimeFrom.slice(11, -13)} -{" "}
                                          {item.dateTimeTo.slice(11, -13)}{" "}
                                          {mofSeletedTicket?.performanceId ==
                                            item.performanceId && (
                                            <i
                                              className="fa fa-check"
                                              aria-hidden="true"
                                            />
                                          )}
                                        </Button>
                                      </div>
                                    </div>
                                  )
                                )}
                              </div>
                            )
                          )}

                          {mofNotSelected && (
                            <Alert color="primary">{mofNotSelected}</Alert>
                          )}

                          <Field
                            name="agentRefNumber"
                            type="text"
                            label="Agent Reference Number"
                            component={RenderField.RenderTextField}
                            className="form-control"
                          />

                          <br />
                          {isFormLoading ? null : (
                            <>
                              {formAddOn.map((add, index) => (
                                <div key={index}>
                                  <Label>
                                    &nbsp; &nbsp; &nbsp;{" "}
                                    <Input
                                      type="checkbox"
                                      id={add.addonFormId}
                                      value={add.addonPrice}
                                      name={add.addonName}
                                      onChange={(e) => checkboxvalues(e)}
                                    />{" "}
                                    {add.addonName} &nbsp; &nbsp;
                                    {"AED " + add.addonPrice}
                                  </Label>
                                </div>
                              ))}

                              {/* {tempErr == true && (
                                <Alert color="danger">Addon Not Avilable</Alert>
                              )} */}
                            </>
                          )}

                          <Label>
                            Total Fare &nbsp;&nbsp;&nbsp; : &nbsp;
                            {id == 147 ? (
                              <>{mofCartData?.totalAmount} </>
                            ) : (
                              Number(
                                adultfare * numOfAdult +
                                  childfare * numOfChild +
                                  addonPrice * numOfAdult +
                                  addonPrice * numOfChild
                              )
                            )}
                            &nbsp;
                          </Label>

                          <br />

                          {LoggedIn == 1 ? (
                            <>
                              {userdata.b2b == true ||
                              userdata.b2bUser == true ? (
                                <>
                                  {showOption === true ? null : (
                                    <>
                                      <div className="single-widget-search-input-title">
                                        <i className=" " /> Payment Method
                                      </div>
                                      <Row>
                                        <Col>
                                          <Field
                                            name="bookPaymentMode"
                                            component="input"
                                            type="radio"
                                            checked="checked"
                                            value="1"
                                            //  onChange={(e) => settktType(e.target.value)}
                                          />{" "}
                                          Online
                                        </Col>
                                        <Col>
                                          <Field
                                            name="bookPaymentMode"
                                            component="input"
                                            type="radio"
                                            value="2"
                                            //  onChange={(e) => settktType(e.target.value)}
                                          />{" "}
                                          Credit
                                          {insuffBal == 1 ? (
                                            <>
                                              {" "}
                                              <Alert color="primary">
                                                insufficient Balance
                                              </Alert>
                                            </>
                                          ) : null}
                                        </Col>
                                      </Row>
                                      {paymentSelection == 1 ? (
                                        <>
                                          {" "}
                                          <Alert color="primary">
                                            Select Payment Method
                                          </Alert>
                                        </>
                                      ) : null}
                                      <br />
                                    </>
                                  )}
                                  {totalerr && (
                                    <>
                                      {" "}
                                      <Alert color="danger">
                                        Something Wrong Please Try Again
                                      </Alert>
                                    </>
                                  )}
                                  <div className="d-flex justify-content-end">
                                    <Button
                                      color="primary"
                                      className="btn btn-yellow"
                                      onClick={handleSubmit((values) =>
                                        onSubmit({
                                          ...values,
                                          pill: 1,
                                        })
                                      )}
                                    >
                                      Book Now
                                    </Button>{" "}
                                    &nbsp;&nbsp;&nbsp;
                                    {showOption === true ? null : (
                                      <>
                                        {" "}
                                        <Button
                                          color="primary"
                                          className="btn btn-yellow"
                                          onClick={handleSubmit((values) =>
                                            onSubmit({
                                              ...values,
                                              pill: 2,
                                            })
                                          )}
                                        >
                                          Add To cart
                                        </Button>
                                      </>
                                    )}
                                  </div>
                                </>
                              ) : (
                                <>
                                  <div className="d-flex justify-content-end">
                                    {/* <Button
                                  color='primary'
                                  type='submit'
                                  className='btn btn-yellow'
                                  disabled={submitting}>
                                  Book Now
                                </Button> */}
                                    <Button
                                      color="primary"
                                      className="btn btn-yellow"
                                      onClick={handleSubmit((values) =>
                                        onSubmit({
                                          ...values,
                                          pill: 1,
                                        })
                                      )}
                                    >
                                      Book Now
                                    </Button>{" "}
                                    &nbsp;&nbsp;&nbsp;
                                    {showOption === true ? null : (
                                      <>
                                        {" "}
                                        <Button
                                          color="primary"
                                          className="btn btn-yellow"
                                          onClick={handleSubmit((values) =>
                                            onSubmit({
                                              ...values,
                                              pill: 2,
                                            })
                                          )}
                                        >
                                          Add To cart
                                        </Button>
                                      </>
                                    )}
                                  </div>
                                </>
                              )}

                              {/* <div className='d-flex justify-content-end'>
                         
                                <Button
                                  color='primary'
                                  className='btn btn-yellow'
                                  onClick={handleSubmit((values) =>
                                    onSubmit({
                                      ...values,
                                      pill: 1,
                                    })
                                  )}>
                                  Book Now
                                </Button>{" "}
                                &nbsp;&nbsp;&nbsp;
                                {showOption === true ? null : (
                                  <>
                                    {" "}
                                    <Button
                                      color='primary'
                                      className='btn btn-yellow'
                                      onClick={handleSubmit((values) =>
                                        onSubmit({
                                          ...values,
                                          pill: 2,
                                        })
                                      )}>
                                      Add To cart
                                    </Button>
                                  </>
                                )}
                              </div> */}
                            </>
                          ) : (
                            <>
                              <div className="d-flex justify-content-end">
                                {/* <Button
                                  color='primary'
                                  type='submit'
                                  className='btn btn-yellow'
                                  disabled={submitting}>
                                  Book Now
                                </Button> */}
                                <Button
                                  color="primary"
                                  className="btn btn-yellow"
                                  onClick={handleSubmit((values) =>
                                    onSubmit({
                                      ...values,
                                      pill: 1,
                                    })
                                  )}
                                >
                                  Book Now
                                </Button>
                                &nbsp;&nbsp;&nbsp;
                                <Button
                                  color="primary"
                                  className="btn btn-yellow"
                                  onClick={handleSubmit((values) =>
                                    onSubmit({
                                      ...values,
                                      pill: 3,
                                    })
                                  )}
                                >
                                  Add To cart
                                </Button>
                              </div>
                            </>
                          )}
                        </Form>
                      </CardBody>
                      {/* <CardFooter></CardFooter>  */}
                    </Card>
                  </div>

                  <div>
                    <br />
                    <br />
                  </div>
                  <CardBody>
                    <div className="contact">
                      {" "}
                      <div className="text-lg-center ">
                        <p>
                          {" "}
                          <div className="anger-text">Enquiry</div>
                        </p>
                      </div>
                    </div>
                    <br />
                    <Row>
                      <Col sm={6}>
                        <br />
                        <img
                          src={publicUrl + "assets/img/callORwhatsapp.gif"}
                          height="150px"
                          width="150px"
                        />
                      </Col>

                      <Col sm={6}>
                        <b>
                          <b>Park Ticket </b>
                          <p> +971 52 909 3077</p>
                          <p> +971 52 909 3078</p>
                          <p> +971 52 909 3079</p>
                          <p> +971 52 909 3081</p>
                          <b>Tourist Visa</b>
                          <p> +971 52 909 3082</p>
                          <b>Tour Package</b>
                          <p> +971 52 909 3076</p>
                        </b>
                      </Col>
                    </Row>
                    <hr />

                    <Row>
                      <Col sm={2}></Col>
                      {/* <Col sm={2}>
                        <p></p>
                      </Col> */}
                      <Col sm={5}>
                        <br />
                        <b>
                          <b>WEB SUPPORT </b>

                          <p> +971 50 421 7788</p>
                        </b>
                      </Col>
                      <Col sm={5}>
                        <img
                          src={publicUrl + "assets/img/helpline.gif"}
                          height="150px"
                          width="150px"
                        />
                      </Col>
                    </Row>
                    <div className="contact">
                      {" "}
                      <div class="text-lg-center">
                        <a
                          href="mailto: parmar@parmartours.com"
                          className="anger-text"
                        >
                          {" "}
                          Click and Send&nbsp;&nbsp;
                          <i
                            className="fa fa-envelope-o fa-1x"
                            aria-hidden="true"
                          ></i>
                        </a>
                      </div>
                    </div>
                  </CardBody>
                </Col>
              </Row>
            </>
          )}
        </div>
      </div>

      <div>
        <div className="container">
          <h4 className="single-page-small-title">You May Also Like</h4>
          <Attractionbygroup attList={attractionList} />
        </div>
      </div>
      <Subscribe />

      <Footer />
    </>
  );
};
export default reduxForm({
  form: "TicketBookingForm",
  // asyncBlurFields: ["bookCustomerName"],
  validate,
  onSubmitSuccess: AfterSubmit,
})(Packdetail);
